<template>
<!--  <div style="background-color: #FAFAFA;padding-top: 25px">-->
<!--    <div style="background-color: white">-->
<!--      <div class="center">-->
<!--        <div style="height: 120px;display: flex;align-items: center">-->
<!--          <Select v-model="cgmc" style="width: 150px;margin-right: 10px">-->
<!--            <Option value="title">成果名称</Option>-->
<!--            <Option value="colleges">高校名称</Option>-->
<!--            <Option value="expertscompleting">发明人</Option>-->
<!--            <Option value="keywords">技术关键词</Option>-->
<!--          </Select>-->
<!--          <Input style="width: 350px;margin-right: 30px" v-model="input"/>-->
<!--          <div style="color:white;border-radius:2px;height: 40px;cursor: pointer;width: 100px;line-height: 40px;text-align: center;background-color: #36c2cf" @click="search()">检索</div>-->
<!--        </div>-->
<!--        <div>-->
<!--          <Table :columns="columns1" :data="data1" @on-row-click="seeDetail" :disabled-hover="true"></Table>-->
<!--        </div>-->
<!--        <div style="padding-top: 40px;overflow: hidden;padding-bottom: 200px">-->
<!--          <Page :total="totalPage" show-elevator style="margin-top: 20px;float: right" @on-change="changePage" />-->
<!--&lt;!&ndash;          <Page :total="100" show-elevator />&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <div class="patent-search-root">
    <div class="patent-search">
      <Select v-model="cgmc" style="width: 130px;height: 40px;">
        <Option value="title">成果名称</Option>
        <Option value="colleges">高校名称</Option>
        <Option value="expertscompleting">发明人</Option>
        <Option value="keywords">技术关键词</Option>
      </Select>
      <Input v-model="input" style="width: 314px" search enter-button="搜索" placeholder="请输入关键词进行搜索"
             @on-search="search"/>
    </div>

    <div class="ps-list">
      <div class="ps-body" v-for="(item,index) in data1" :key="index" @click="seeDetail(item)">
        <img class="psb-img"
             src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/web-static/znsearch/images/i2.png"/>
        <div class="psb-center">
          <div class="psbc-title ellipse-1">{{item.title}}</div>
          <div class="dp-flex"><span class="psbc-tagA">{{ item.achieveType }}</span>
          </div>
          <div class="psbc-text ellipse-1">归属高校：{{ item.colleges }}</div>
          <div class="psbc-text ellipse-1">成果发明人：{{ item.expertsCompleting }}</div>
        </div>
        <div class="psb-right">
          <div class="psb-right-button display-flex-center">咨询成果</div>
<!--          <div class="psb-right-focus display-flex-center">-->
<!--            <Icon type="ios-heart-outline" style="color: rgba(15, 98, 223, 1);font-size: 20px;margin-right: 5px" />-->
<!--            关注</div>-->
        </div>
      </div>
      <div style="display: flex;flex-direction: row-reverse;padding: 40px 20px">
        <Page style="margin-top: 20px;float: right" :total="totalPage"
              :page-size="params.pageSize" show-elevator @on-change="pageChange"/>
      </div>
    </div>

  </div>
</template>

<script>
  import {getResult,getResultDetail} from "@/plugins/api/resourceApi";
  import {errorToast,warnToast} from "@/plugins/tools/util";

  export default {
    name: "PatentSearch",
    mounted() {
      let keyword = this.$route.query.keyword
      this.params.keywords = keyword;
      this.input = keyword
      this.cgmc = "keywords";
      this.getResultList();
    },
    data(){
      return {
        params:{
          keywords:"",
          title:"",
          colleges:'',
          expertscompleting:'',
          pageNum:1,
          pageSize:4,
        },
        totalPage:1,
        cgmc:'',
        input:'',
        data1: [
        ]
      }
    },
    methods:{
      seeDetail(row,index){
      let routeUrl = this.$router.resolve({
        path: "/home/PatentSearchDetail",//新页面地址
        query: { id: row.id }//携带的参数
      });
      window.open(routeUrl.href, "_blank");
      },

      async getResultList() {
        let flag = false;
        for(let item in this.params){
          if(item !== 'pageNum' && item !== 'pageSize'){
            if(this.params[item] != ""){
              flag = true
            }
          }
        }
        if(flag == false){
          warnToast("请至少选择一种进行查询")
        }else {
          let _that = this;
          let json = await getResult(this.params);
          if (json.code == 0) {
            this.data1 = json.result.list;
            this.totalPage = json.result.total;
            // keywords:"",
            //     title:"",
            //     colleges:'',
          } else {
            errorToast(json.message)
          }
        }
      },
      search() {
        this.params.pageNum = 1;
        this.params[this.cgmc] = this.input;
        for(let item in this.params){
          if(this.params[item] != "" && item != this.cgmc){
            this.params[item] = "";
          }
        }

        this.getResultList();
      },
      changePage(index){
        this.params.pageNum = index;
        this.getResultList()
      }
    }
  }
</script>

<style scoped lang="scss">
.patent-search-root{
  width: 1200px;
  margin: 0 auto;
}
.patent-search {
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
}

.ps-list {
  border-radius: 6px;
  box-shadow: 1px 1px 10px rgba(229, 239, 249, 1);
}

.ps-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 182px;
  cursor: pointer;
  border-bottom: 1px solid #E4E4E4;;
}

.psb-img {
  width: 214px;
  height: 120px;
  margin-left: 20px;
  flex-shrink: 0;
}

.psb-center {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
}

.psbc-title {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
}

.psbc-tagA {
  background-color: #FFDBCD;
  padding: 7px 10px;
  font-size: 14px;
  font-weight: 400;
  color: #FE7930;
  border-radius: 4px;
}

.psbc-tagB {
  background-color: rgba(202, 223, 254, 1);
  padding: 7px 10px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(15, 98, 223, 1);
  border-radius: 4px;
  margin-left: 10px;
}

.psbc-text {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.psb-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.psb-right-button {
  width: 120px;
  height: 34px;
  background: #DCEAFE;
  border: 1px solid #0F62DF;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #0F62DF;
  margin-right: 37px;
}

.psb-right-focus{
  width: 120px;
  height: 34px;
  background: #FFFFFF;
  border: 1px solid #0F62DF;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  margin-right: 37px;
  color: #0F62DF;
  margin-top: 10px;
}

  /deep/ .ivu-select-selection{
    height: 40px!important;
    line-height: 40px!important;
  }
  /deep/ .ivu-select-selected-value{
    height: 40px!important;
    line-height: 40px!important;
  }
</style>
